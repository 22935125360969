<template>
  <div class="searchContainer">
    <b-form-input class="wineSearch" ref="wineSearch" v-model="searchFilter" :placeholder="placeholder"></b-form-input>
    <span v-if="searchFilter != null && searchFilter.length > 0" @click="searchFilter = ''">
      <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.728 2.243a6.002 6.002 0 0 1 0 8.485 6.002 6.002 0 0 1-8.485 0 6.002 6.002 0 0 1 0-8.485 6.002 6.002 0 0 1 8.485 0zM9.116 4.788l-.848-.848L6.57 5.637 4.874 3.94l-.849.848 1.697 1.697-1.697 1.697.849.849L6.57 7.334 8.268 9.03l.848-.849L7.42 6.485l1.697-1.697z"
          fill="#CED4DA" fill-rule="nonzero" />
      </svg>
    </span>
  </div>
</template>

<script>


export default {
  name: 'FrontendFilterSearch',
  data() {
    return {
      searchFilter: '',
    }
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    }
  },
  watch: {
    searchFilter: function (val) {
      this.$root.$emit('changedSearchFilter', val)
    }
  },
}

</script>
<style scoped>
input.wineSearch {
  min-width: 400px;
  color: var(--color-gray-150);
  background-color: var(--color-gray-300);
}
input::placeholder {
  color: var(--color-gray-150);
}
</style>